<template>
    <v-app id="inspire">
        <v-app-bar app clipped-right color="#252525" class="d-print-none">
            <v-app-bar-nav-icon
                @click="showSidebar"
                v-show="currentUser.uid"
                color="white"
            >
                <template v-slot:default>
                    <v-icon>fas fa-bars</v-icon>
                </template>
            </v-app-bar-nav-icon>
            <img
                @click="$router.push('/')"
                height="30"
                align-center
                center
                contain
                src="@/assets/delallo-white.png"
                :class="{ home: true, padRight: currentUser.uid }"
                style="margin: 0 auto; height: 30px;"
            />
        </v-app-bar>
        <sidebar ref="sidebar"></sidebar>
        <v-main>
            <v-container fluid fill-height align-start>
                <router-view></router-view>
            </v-container>
        </v-main>
        <v-footer
            app
            color="#252525"
            class="white--text pt-0 pb-0 d-print-none"
        >
            <span>Sitesquad Mobile Pulling</span>
            <v-spacer></v-spacer>
            <span>&copy; {{ new Date().getFullYear() }}</span>
        </v-footer>
        <dialog-loader ref="dialogLoader"></dialog-loader>
        <confirm-dialog ref="confirmDialog"></confirm-dialog>
        <order-customer-service-note
            ref="orderCustomerServiceNote"
        ></order-customer-service-note>
        <order-gift-message ref="orderGiftMessage"></order-gift-message>
    </v-app>
</template>
<style src="@/assets/styles/styles.css"></style>

<style>
/* @import 'https://cdn11.bigcommerce.com/s-cjh14ahqln/stencil/bd1c2910-01b4-013a-4279-2a7fc68f3254/e/a8707b30-0e61-013a-66c7-423dbc576a68/css/theme-01c0b020-18a8-013a-b56d-26709954fca0.css';
*/
.v-application {
    font-family: Utopia, Helvetica, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

.v-application .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.home {
    cursor: pointer;
}

.home.padRight {
    padding-right: 37px;
}

.v-messages__message {
    line-height: 1.25em !important;
}

.v-data-table thead th {
    text-align: center !important;
}
</style>

<script>
import { mapState } from 'vuex';
import Sidebar from './components/Sidebar';
import DialogLoader from '@/components/DialogLoader';
import ConfirmDialog from '@/components/ConfirmDialog';
import OrderCustomerServiceNote from '@/components/OrderCustomerServiceNote';
import OrderGiftMessage from '@/components/OrderGiftMessage';

export default {
    name: 'App',
    components: {
        Sidebar,
        DialogLoader,
        ConfirmDialog,
        OrderCustomerServiceNote,
        OrderGiftMessage
    },
    props: {
        source: String
    },
    computed: {
        ...mapState(['currentUser', 'unassignedOrders']),
        isHome() {
            return this.$route.path === '/';
        }
    },
    mounted() {
        this.$root.$dialogLoader = this.$refs.dialogLoader;
        this.$root.$confirmDialog = this.$refs.confirmDialog;
        this.$root.$orderCustomerServiceNote = this.$refs.orderCustomerServiceNote;
        this.$root.$orderGiftMessage = this.$refs.orderGiftMessage;
    },
    data: () => ({
        drawer: null,
        drawerRight: null,
        right: false,
        left: false
    }),
    methods: {
        showSidebar() {
            this.$refs.sidebar.setValue(!this.drawer);
        },
        back() {
            this.$router.back();
        }
    },
    created() {
        this.$store.dispatch('bindUnassignedOrders');
        this.$store.dispatch('bindAssignedOrders');
        this.$store.dispatch('bindUsers');
    }
};
</script>
