/**
 *
 * @param {Array} data // format {{name: 'John', lastname: 'Doe'}, {name: 'Jane', lastname: 'Doe'}}
 * @param {String} filename
 */
function createCsv(data, filename) {
    const rows = [];
    const header = Object.keys(data[0]);
    rows.push(header);

    data.forEach(item => {
        rows.push(formatRow(item, header));
    });

    let csvContent = '';
    rows.forEach(row => {
        csvContent += row.join(',') + '\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', objUrl);
    link.setAttribute('download', filename);
    link.click();
}

function formatRow(row, header) {
    let data = [];
    for (const item in header) {
        data.push(row[header[item]]);
    }
    return data;
}

export { createCsv };
