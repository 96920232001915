import { db } from '@/firebase';

/**
 * return product by ID from big-commerce
 * @returns {*}
 */
async function getProductById(productId) {
    const response = await fetch(
        `${process.env.VUE_APP_FIREBASE_FUNCTION_ROOT}/getProductById/?id=${productId}`
    );
    return await response.json();
}

/**
 * return product by SKU from big-commerce
 * @returns {*}
 */
async function getProductBySku(productSku) {
    const response = await fetch(
        `${process.env.VUE_APP_FIREBASE_FUNCTION_ROOT}/getProductBySku/?sku=${productSku}`
    );
    return await response.json();
}

/**
 * return product by UPC from big-commerce
 * @returns {*}
 */
async function getProductByUpc(productUpc) {
    const response = await fetch(
        `${process.env.VUE_APP_FIREBASE_FUNCTION_ROOT}/getProductByUpc/?upc=${productUpc}`
    );
    return await response.json();
}

/**
 * return order products from big-commerce
 * @returns {*}
 */
async function getOrderProducts(orderId) {
    const response = await fetch(
        `${process.env.VUE_APP_FIREBASE_FUNCTION_ROOT}/getOrderProducts/?id=${orderId}`
    );
    return await response.json();
}

/**
 * return product images from big-commerce
 * @returns {*}
 */
async function getProductImages(productId) {
    const response = await fetch(
        `${process.env.VUE_APP_FIREBASE_FUNCTION_ROOT}/getProductImages/?id=${productId}`
    );
    return await response.json();
}

async function getBundleItems(parentProductId) {
    return db
        .collection('verification_item')
        .where('orderItem.parent_order_product_id', '==', parentProductId)
        .get();
}

function getOutOfStockItems() {
    return db
        .collection('verification_item')
        .where('status', '==', 'out-of-stock')
        .get();
}

function getRestockedItems() {
    return db
        .collection('restock_item')
        .limit(500)
        .get();
}

export {
    getProductById,
    getProductBySku,
    getProductByUpc,
    getOrderProducts,
    getProductImages,
    getBundleItems,
    getOutOfStockItems,
    getRestockedItems
};
