<template>
    <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        v-show="currentUser.uid"
        class="text-left"
    >
        <template v-slot:prepend>
            <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title class="title small">
                        Mobile Pulling
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
                <v-list-item-avatar>
                    <v-avatar color="primary" size="36">
                        <span
                            class="white--text headline"
                            v-text="
                                currentUser.displayName
                                    ? currentUser.displayName.charAt(0)
                                    : ''
                            "
                        ></span>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title
                        >{{ currentUser.displayName }} <br
                    /></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item dense v-if="currentUser.role.includes('ADMIN')">
                <v-list-item-content>
                    <v-list-item-title
                        >Active Orders ({{
                            activeOrdersCount
                        }})</v-list-item-title
                    >

                    <v-list-item-subtitle class="counted-at"
                        >As counted at:
                        <strong>{{
                            activeOrdersCountedAt
                        }}</strong></v-list-item-subtitle
                    >
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
        </template>

        <v-divider></v-divider>

        <v-list dense class="pb-0">
            <v-list-item router to="/">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-home</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Main Menu</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-dialog transition="dialog-top-transition">
            <template v-slot:activator="{ on, attrs }">
                <a
                    class="v-list-item v-list-item--link theme--light v-list-item--dense"
                    role="link"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-list-item-icon class="mr-2">
                        <v-icon small>fa-filter</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Filters Menu</v-list-item-title>
                    </v-list-item-content>
                </a>
            </template>
            <template v-slot:default="dialog">
                <v-card flat>
                    <v-card-text>
                        <v-container fluid>
                            <v-radio-group v-model="selectedFilter" column>
                                <v-radio
                                    label="Show gift orders"
                                    color="primary"
                                    value="gift"
                                    class="pa-2"
                                ></v-radio>
                                <v-radio
                                    label="Show grocery orders"
                                    color="primary"
                                    value="grocery"
                                    class="pa-2"
                                ></v-radio>
                                <v-radio
                                    label="Show perishable orders"
                                    color="primary"
                                    value="perishable"
                                    class="pa-2"
                                ></v-radio>
                                <v-radio
                                    label="Show Non-Perishable orders"
                                    color="primary"
                                    value="non-perishable"
                                    class="pa-2"
                                ></v-radio>
                            </v-radio-group>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            color="error"
                            text
                            @click="(dialog.value = false), resetFilter()"
                            >Reset Filters</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            :disabled="selectedFilter === ''"
                            @click="(dialog.value = false), applyFilter()"
                            >Apply Filters</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
        <v-list dense class="pb-0 pt-0">
            <v-list-item router to="/search">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-search</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Search for Order</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list dense class="pb-0 pt-0">
            <v-list-item router to="/cart">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-barcode</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Scan Cart</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list dense class="pt-0" v-show="currentUser.role.includes('ADMIN')">
            <v-list-item router to="/admin">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Admin</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list-group :value="false" no-action>
            <template v-slot:activator>
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-list-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Reports</v-list-item-title>
                </v-list-item-content>
            </template>

            <v-list-item router to="/report/out-of-stock" class="pl-6">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-list-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title
                        >Out of Stock Items Report</v-list-item-title
                    >
                </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/report/restock" class="pl-6">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-list-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Restock Items Report</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/report/cartstatus" class="pl-6">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-list-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Active Cart Report</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/timetracker" class="pl-6">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-list-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Time Tracker Report</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/report/inventory" class="pl-6">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-list-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Inventory Report</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/report/user-activity" class="pl-6">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-list-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>User Activity</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>

        <v-list dense class="pt-0">
            <v-list-item router to="/batch-orders/search-by-sku">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-gifts</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Batch Pulling by SKU</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list dense class="pt-0">
            <v-list-item router to="/batch-orders/search-by-email">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-gifts</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title
                        >Batch Pulling by Email</v-list-item-title
                    >
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list dense class="pt-0">
            <v-list-item router to="/customer-notes">
                <v-list-item-icon class="mr-2">
                    <v-icon small>fa-list-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Customer Notes</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <template #append>
            <div class="pa-2">
                <v-btn block @click="logout" color="error">
                    Logout
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<style>
.counted-at {
    font-size: 12px !important;
}
.v-list-group .v-icon.fa-chevron-down {
    font-size: 16px;
}
</style>

<script>
import { orders, timeTracker } from '@/data/index';
import { mapState } from 'vuex';

export default {
    name: 'Sidebar',
    props: {
        source: String
    },
    data: () => ({
        drawer: null,
        selectedFilter: '',
        activeOrdersCount: 0,
        activeOrdersCountedAt: ''
    }),
    computed: {
        ...mapState(['currentUser', 'activeFilter'])
    },
    mounted() {
        this.selectedFilter = this.activeFilter;
        orders.getOrdersCount().then(snapshot => {
            const document = snapshot.data();
            this.activeOrdersCount = document.active_orders;
            this.activeOrdersCountedAt = document.active_orders_counted_at;
        });
    },
    methods: {
        logout() {
            if (this.$store.state.activeTimeTracker == '') {
                this.$store.dispatch('logout');
                return;
            }
            timeTracker
                .stopTimerWithPause()
                .then(() => {
                    this.$store.dispatch('logout');
                })
                .catch(error => {
                    console.error('Error updating document: ', error);
                });
        },
        setValue(value) {
            this.drawer = value;
        },
        applyFilter() {
            this.$store.dispatch('applyFilter', this.selectedFilter);
            this.drawer = false;
        },
        resetFilter() {
            this.selectedFilter = '';
            this.$store.dispatch('resetFilter');
            this.drawer = false;
        }
    }
};
</script>
