import Vue from 'vue';
import VueRouter from 'vue-router';
import { auth } from '../firebase';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import(/* webpackChunkName: "home" */ '../views/Login.vue'),
        props: true
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () =>
            import(/* webpackChunkName: "home" */ '../views/ResetPassword.vue')
    },
    {
        path: '/',
        name: 'Home',
        component: () =>
            import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
            requiresAuth: true
        },
        props: true
    },
    {
        name: 'Pull',
        path: '/pull/:id',
        component: () =>
            import(/* webpackChunkName: "pull" */ '../views/Pull.vue'),
        meta: {
            requiresAuth: true
        },
        params: true
    },
    {
        name: 'Search',
        path: '/search',
        component: () =>
            import(/* webpackChunkName: "search" */ '../views/Search.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'Scan Product',
        path: '/product',
        component: () =>
            import(/* webpackChunkName: "search" */ '../views/ScanProduct.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'Product',
        path: '/product/:id',
        component: () =>
            import(/* webpackChunkName: "search" */ '../views/Product.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'Scan',
        path: '/cart',
        component: () =>
            import(/* webpackChunkName: "scan" */ '../views/Scan.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'Order',
        path: '/order',
        component: () =>
            import(/* webpackChunkName: "order" */ '../views/Order.vue'),
        meta: {
            requiresAuth: true
        },
        props: true
    },
    {
        name: 'Complete',
        path: '/complete/:id',
        component: () =>
            import(/* webpackChunkName: "order" */ '../views/Complete.vue'),
        meta: {
            requiresAuth: true
        },
        params: true
    },
    {
        name: 'GiftMessage',
        path: '/gift-message/:id',
        component: () =>
            import(/* webpackChunkName: "order" */ '../views/GiftMessage.vue'),
        meta: {
            requiresAuth: true
        },
        params: true
    },
    {
        name: 'Admin',
        path: '/admin',
        component: () =>
            import(/* webpackChunkName: "order" */ '../views/Admin.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'OrderBarcodeScan',
        path: '/order/:id/scan',
        component: () =>
            import(
                /* webpackChunkName: "order" */ '../views/OrderBarcodeScan.vue'
            ),
        meta: {
            requiresAuth: true
        },
        params: true
    },
    {
        name: 'OutOfStockReport',
        path: '/report/out-of-stock',
        component: () =>
            import(
                /* webpackChunkName: "order" */ '../views/Reports/OutOfStock.vue'
            ),
        meta: {
            requiresAuth: true
        },
        params: true
    },
    {
        name: 'RestockReport',
        path: '/report/restock',
        component: () =>
            import(
                /* webpackChunkName: "order" */ '../views/Reports/Restock.vue'
            ),
        meta: {
            requiresAuth: true
        },
        params: true
    },
    {
        name: 'CartStatusReport',
        path: '/report/cartstatus',
        component: () =>
            import(
                /* webpackChunkName: "order" */ '../views/Reports/CartStatus.vue'
            ),
        meta: {
            requiresAuth: true
        },
        params: true
    },
    {
        path: '*',
        component: () =>
            import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
            requiresAuth: true
        },
        props: true
    },
    {
        name: 'ReassignCartId',
        path: '/reassign-cart-id/:order_id',
        component: () =>
            import(
                /* webpackChunkName: "home" */ '../views/ReAssignCartId.vue'
            ),
        meta: {
            requiresAuth: true
        },
        params: true
    },
    {
        name: 'TimeTracker',
        path: '/timetracker',
        component: () =>
            import(/* webpackChunkName: "home" */ '../views/TimeTracker.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'InventoryReport',
        path: '/inventory-report',
        component: () =>
            import(
                /* webpackChunkName: "home" */ '../views/InventoryReport.vue'
            ),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'CartStatusReport',
        path: '/report/inventory',
        component: () =>
            import(
                /* webpackChunkName: "order" */ '../views/Reports/Inventory.vue'
            ),
        meta: {
            requiresAuth: true
        },
        params: true
    },
    {
        name: 'BatchOrdersSkuSearch',
        path: '/batch-orders/search-by-sku',
        component: () =>
            import(
                /* webpackChunkName: "home" */ '../views/BatchOrders/SearchBySku.vue'
            ),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'BatchOrdersEmailSearch',
        path: '/batch-orders/search-by-email',
        component: () =>
            import(
                /* webpackChunkName: "home" */ '../views/BatchOrders/SearchByEmail.vue'
            ),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'BatchOrdersReport',
        path: '/batch-orders/report',
        component: () =>
            import(
                /* webpackChunkName: "home" */ '../views/BatchOrders/Report.vue'
            ),
        meta: {
            requiresAuth: true
        },
        props: true
    },
    {
        name: 'UserActivityReport',
        path: '/report/user-activity',
        component: () =>
            import(
                /* webpackChunkName: "home" */ '../views/Reports/UserActivity.vue'
            ),
        meta: {
            requiresAuth: true
        },
        props: true
    },
    {
        name: 'CustomerNotes',
        path: '/customer-notes',
        component: () =>
            import(
                /* webpackChunkName: "order" */ '../views/CustomerNotes.vue'
            ),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'MultiOrderPickingSelection',
        path: '/multi-order-picking-selection',
        component: () =>
            import(
                /* webpackChunkName: "order" */ '../views/MultiOrderPickup/Home.vue'
            ),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'MultiOrderPulling',
        path: '/multi-order-picking-pulling',
        component: () =>
            import(
                /* webpackChunkName: "order" */ '../views/MultiOrderPickup/Pull.vue'
            ),
        meta: {
            requiresAuth: true
        },
        props: true
    },
    {
        name: 'MultiOrderComplete',
        path: '/multi-order-picking-complete',
        component: () =>
            import(
                /* webpackChunkName: "order" */ '../views/MultiOrderPickup/Complete.vue'
            ),
        meta: {
            requiresAuth: true
        },
        props: true
    },
    {
        name: 'OrderShipStationBarcode',
        path: '/order-ship-station-barcode',
        component: () =>
            import(
                /* webpackChunkName: "order" */ '../views/OrderShipStationBarcode'
            ),
        meta: {
            requiresAuth: true
        },
        props: true
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
    if (to.path === '/login' || to.path == '/reset-password') {
        auth.onAuthStateChanged(user => {
            if (user) {
                store.dispatch('fetchUserProfile', user);
                next('/');
            } else next();
        });
    }

    if (to.path.includes('/pull')) {
        if (!to.params.id) next('/');
        else next();
    }

    const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
    if (requiresAuth) {
        auth.onAuthStateChanged(user => {
            if (user) {
                store.dispatch('fetchUserProfile', user);
                next();
            } else next('/login');
        });
    } else {
        next();
    }
});

export default router;
