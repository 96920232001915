import { db } from '@/firebase';
import { config, helper } from '@/data/index';
import store from '@/store/index';

/**
 * returns all the users from the DB (Firebase)
 * @returns {*}
 */

function getUsers() {
    return db.collection('users').get();
}

async function getEmailSuffix() {
    const adminConfig = await config.getAdminConfig();
    let emailSuffix = '';
    adminConfig.forEach(doc => {
        emailSuffix = doc.data().users?.email_suffix;
    });
    return emailSuffix;
}

function logUserLoginEvent() {
    const email = store.state.currentUser.email;
    helper.logData(`User ${email} is logged in from ${navigator.userAgent}`);
}

function logUserLogoutEvent() {
    const email = store.state.currentUser.email;
    helper.logData(
        `User ${email} is now logged out from ${navigator.userAgent}`
    );
}

export { getUsers, getEmailSuffix, logUserLoginEvent, logUserLogoutEvent };
