<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            :width="options.width"
            v-bind:style="{ zIndex: options.zIndex }"
        >
            <v-card :color="options.color" dark>
                <v-card-text class="pt-5 text-center">
                    {{ message }}
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0 mt-4"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        message: null,
        options: {
            color: 'primary',
            width: 290,
            zIndex: 200
        }
    }),
    methods: {
        /**
         * Show loader
         * @param {string} message
         * @param {object} options
         */
        show(message, options) {
            this.dialog = true;
            this.message = message;
            this.options = Object.assign(this.options, options);
        },
        /**
         * Hide loader
         */
        hide() {
            this.dialog = false;
            this.message = null;
        },
        updateMessage(message) {
            this.message = message;
        }
    }
};
</script>
