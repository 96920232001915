<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        v-bind:style="{ zIndex: 200 }"
    >
        <v-card>
            <v-card-title class="headline">
                Gift Message # {{ orderId }}
            </v-card-title>

            <v-card-text>
                {{ giftMessage }}
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click.native="hide">
                    OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import * as data from '@/data/index';

export default {
    data: () => {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            orderId: '',
            giftMessage: ''
        };
    },
    mounted() {},
    methods: {
        show(order) {
            if (!data.orderGiftMessage.hasGiftMessage(order)) {
                this.giftMessage = '';
                this.resolve(true);
                this.dialog = false;
            }
            this.orderId = order.order.id;
            this.giftMessage = this.getOrderGiftMessage(order);
            this.dialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        getOrderGiftMessage(order) {
            return data.orderGiftMessage.getGiftMessage(order);
        },

        hide() {
            this.resolve(true);
            this.dialog = false;
            this.note = '';
        }
    }
};
</script>
