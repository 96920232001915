import { db } from '@/firebase';

/**
 * return general config from DB (Firebase)
 * @returns {*}
 */
function getConfig() {
    return db
        .collection('config')
        .where('admin', '==', false)
        .get();
}

/**
 * return admin config from DB (Firebase)
 * @returns {*}
 */
async function getAdminConfig() {
    return db
        .collection('config')
        .where('admin', '==', true)
        .get();
}

function getBigCommerceOrderStatusMapping() {
    return db
        .collection('config')
        .doc('bc_order_statuses')
        .get();
}

async function getBigCommerceOrderStatusId(status) {
    let statusId = 0;
    await getBigCommerceOrderStatusMapping()
        .then(querySnapshot => {
            const orderStatusMapping = querySnapshot.data();
            if (orderStatusMapping.bigcommerce.orderStatuses[status]) {
                statusId = orderStatusMapping.bigcommerce.orderStatuses[status];
            }
        })
        .catch(function(error) {
            console.log('Error getting documents: ', error);
        });
    return statusId;
}

async function getShipstationConfig() {
    const adminConfig = await getAdminConfig();
    let shipstationConfig = {};
    adminConfig.forEach(doc => {
        if ('adapters' in doc.data()) {
            if ('shipstation' in doc.data().adapters) {
                shipstationConfig = doc.data().adapters.shipstation;
            }
        }
    });
    return shipstationConfig;
}

async function getStoreUrl() {
    const adminConfig = await getAdminConfig();
    let storeUrl = null;
    await adminConfig.forEach(doc => {
        if ('store_url' in doc.data()) {
            storeUrl = doc.data().store_url;
        }
    });
    return storeUrl;
}

async function getShipstationBarcodePrefix() {
    let shipstationConfig = await getShipstationConfig();
    if ('barcode_prefix' in shipstationConfig) {
        return shipstationConfig.barcode_prefix;
    }
    return '';
}

async function getTimeTrackingOperations() {
    const operations = await db
        .collection('config')
        .doc('time_tracker')
        .get()
        .then(querySnapshot => {
            if (!querySnapshot.exists) {
                return {};
            }
            let data = querySnapshot.data();
            if (data?.operations) {
                return data.operations;
            }
            return {};
        });
    return operations;
}

async function getTimeTrackingoperationById(id) {
    const operations = await getTimeTrackingOperations();
    return id in operations ? operations[id] : '';
}

export {
    getConfig,
    getAdminConfig,
    getStoreUrl,
    getBigCommerceOrderStatusMapping,
    getBigCommerceOrderStatusId,
    getShipstationBarcodePrefix,
    getTimeTrackingOperations,
    getTimeTrackingoperationById
};
