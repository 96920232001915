function getGiftMessage(order) {
    let giftMessage = order.order.customer_message;

    // eslint-disable-next-line prettier/prettier
    const regex2 = /Gift Message:\s*([\s\S]*)/im;
    let result = regex2.exec(giftMessage);
    if (result && 1 in result) {
        giftMessage = result[1];
    }
    return formatGiftMessage(giftMessage);
}

function checkAndGetGiftMessage(order) {
    if (!hasGiftMessage(order)) {
        return '';
    }
    let giftMessage = order.order.customer_message;

    // eslint-disable-next-line prettier/prettier
    const regex2 = /Gift Message:\s*([\s\S]*)/im;
    let result = regex2.exec(giftMessage);
    if (result && 1 in result) {
        giftMessage = result[1];
    }
    return formatGiftMessage(giftMessage);
}

function hasGiftMessage(order) {
    if (!order?.order?.customer_message) {
        return false;
    }
    let giftMessage = order.order.customer_message;
    giftMessage = giftMessage.replace(/(\r\n|\n|\r)/gm, ''); // remove new lines
    if (giftMessage == null || giftMessage === '') {
        return false;
    }

    // eslint-disable-next-line prettier/prettier
    const regex = /Gift Message:\s*([\s\S]*)/im;
    const result = regex.exec(giftMessage);
    if (result && 1 in result && result[1] === 'undefined') {
        return false;
    }

    if (result !== null) {
        return true;
    }
    return !giftMessage.includes(
        'Ship Later Date' // only ship-later date is mentioned in the order comments
    );
}

function formatGiftMessage(giftMessage) {
    giftMessage = giftMessage.replace(/(\r\n|\n|\r)/gm, ''); // remove new lines
    if (giftMessage === 'undefined') {
        return '';
    }
    const regex = '.{1,' + 70 + '}(\\s|$)' + ('|.{' + 70 + '}|.+$');
    return giftMessage.match(RegExp(regex, 'g')).join('\n'); // format gift-message into multiple lines with 70 characters in each line
}

export {
    getGiftMessage,
    checkAndGetGiftMessage,
    hasGiftMessage,
    formatGiftMessage
};
