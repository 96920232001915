import { db } from '@/firebase';
import store from '@/store/index';
import firebase from 'firebase/app';

/**
 * return the order info fetched from Firestore
 * @param orderId
 * @returns {Promise<*>}
 */
async function getOrder(orderId) {
    await syncOrder(orderId);
    return db
        .collection('orders')
        .doc(orderId.toString())
        .get();
}

/**
 * pull order from big-commerce of given ID and save it in Firestore DB
 * @param orderId
 * @returns {Promise<void>}
 */
async function syncOrder(orderId) {
    const getOrderUrl = `${process.env.VUE_APP_FIREBASE_FUNCTION_ROOT}/orderById`;
    await fetch(getOrderUrl + '?id=' + orderId);
}

/**
 * return orders that are assigned to current user from DB (Firebase)
 * @returns {*}
 */
function getAssignedOrders() {
    const currentUserId = store.state.currentUser.uid;
    return db
        .collection('orders')
        .where('assigned', '==', currentUserId)
        .get();
}

function getUnassignedOrders() {
    return db
        .collection('orders')
        .where('assigned', '==', null)
        .orderBy('order.date_created', 'desc')
        .get();
}

function getNextUnassignedOrder(previousOrderId = 0) {
    db.collection('orders')
        .where('assigned', '==', null)
        .limit(1)
        .startAfter(previousOrderId)
        .get();
}

function getOrderVerification(orderId) {
    return db
        .collection('verification')
        .doc('order-' + orderId)
        .get();
}

function getOrderShippingAddresses(orderId) {
    return fetch(
        `${process.env.VUE_APP_FIREBASE_FUNCTION_ROOT}/getOrderShippingAddresses/?id=${orderId}`
    );
}

function getOrdersCount() {
    return db
        .collection('config')
        .doc('orders_count')
        .get();
}

function getOrderShipLaterDate(order) {
    if (!order || !order.order || !order.order.customer_message) {
        return '';
    }
    if (!order.order.customer_message.includes('Ship Later Date')) {
        return '';
    }

    // eslint-disable-next-line no-useless-escape
    let dateType = /(\d{1,2})([\/-])(\d{1,2})\2(\d{4})/;
    let result = order.order.customer_message.match(dateType);
    if (result && 0 in result) {
        return result[0];
    }
    return '';
}

/**
 * return order documents collection matching given status from DB (Firebase)
 * @returns {*}
 */
function getByStatus(status) {
    return db
        .collection('orders')
        .where('internalStatus', '==', status)
        .get();
}

/**
 * return order documents collection not matching given status from DB (Firebase)
 * @returns {*}
 */
function getByNotStatus(statuses) {
    if (typeof statuses === 'string') statuses = [statuses];

    return db
        .collection('orders')
        .where('internalStatus', 'not-in', statuses)
        .get();
}

async function getCustomerOrders(email) {
    const response = await fetch(
        `${
            process.env.VUE_APP_FIREBASE_FUNCTION_ROOT
        }/getCustomerOrders/?email=${encodeURIComponent(email)}`
    );
    return await response.json();
}

async function getVerificationForOrder(order, currentUser) {
    let verification = {};
    await order.verification.get().then(async snap => {
        verification = snap.data();
        delete verification['order'];
        if (verification.user == null) {
            store
                .dispatch('updateVerification', {
                    orderId: order.order.id,
                    data: {
                        user: {
                            id: currentUser.uid,
                            email: currentUser.email,
                            displayName: currentUser.displayName
                        }
                    }
                })
                .then(() => {
                    verification.user = {
                        id: currentUser.uid,
                        email: currentUser.email,
                        displayName: currentUser.displayName
                    };
                });
        }

        verification.items = await loadVerificationItems(verification.items);
    });
    return verification;
}

async function loadVerificationItems(itemsRef) {
    let itemPromises = itemsRef.map(doc => doc.get());
    let items = [];
    await Promise.all(itemPromises).then(snap => {
        snap.forEach(querySnapshot => {
            items.push(querySnapshot.data());
        });
    });
    return items;
}

/**
 * return orders that have particular item from DB (Firebase)
 * @returns {*}
 */
function getOrdersByItem(sku) {
    return db
        .collection('orders')
        .where('internalStatus', '==', 'queued')
        .where('items', 'array-contains', sku)
        .get();
}

/**
 * return orders that belong to given customer from DB (Firebase)
 * @returns {*}
 */
function getOrdersByEmail(email) {
    return db
        .collection('orders')
        .where('internalStatus', '==', 'queued')
        .where('order.customer_email', '==', email)
        .get();
}

function shouldOrderShowCustomerServiceNote(orderId) {
    let data = store.state.canDisplayOrderCustomerServiceNote;
    return !(orderId in data);
}

async function markMergedOrderIds(mainOrderId, mergedOrderIds) {
    const mainOrderRef = db.collection('orders').doc(mainOrderId.toString());
    mainOrderRef.update({
        merged_order_ids: mergedOrderIds
    });
    return mainOrderRef.get();
}

async function mergeOrderItems(order) {
    if (!order.merged_order_ids || order.merged_order_ids.length < 1) {
        return [];
    }
    let mergedOrderIds = order.merged_order_ids;

    let mergedOrderItems = [];
    let promises = mergedOrderIds.map(mergedOrderId => {
        return db
            .collection('orders')
            .doc(mergedOrderId.toString())
            .get()
            .then(snapshot => {
                let order = snapshot.data();
                return getVerificationForOrder(order, store.state.currentUser);
            })
            .then(verification => {
                mergedOrderItems = mergedOrderItems.concat(verification.items);
                return Promise.resolve();
            });
    });
    await Promise.all(promises);

    const orderId = order.order.id.toString();
    let itemsSku = order.items;
    let orderVerificationRef = db
        .collection('verification')
        .doc('order-' + orderId);

    promises = mergedOrderItems.map(item => {
        return db
            .collection('verification_item')
            .doc('order-' + orderId + '-item-' + item.orderItem.id)
            .set(item)
            .then(() => {
                orderVerificationRef.update({
                    items: firebase.firestore.FieldValue.arrayUnion(
                        db
                            .collection('verification_item')
                            .doc(
                                'order-' +
                                    orderId +
                                    '-item-' +
                                    item.orderItem.id
                            )
                    )
                });

                itemsSku.push(item.orderItem.sku);
                return null;
            })
            .catch(err => {
                console.error(err);
            });
    });
    await Promise.all(promises);
    const orderRef = db.collection('orders').doc(orderId);
    orderRef.update({
        items: itemsSku,
        itemCount: itemsSku.length
    });
    return orderRef.get();
}

function getOrderItemsWeight(orderItems) {
    let weight = 0;
    orderItems.map(item => {
        if (typeof item === 'object' && typeof item['weight'] !== 'undefined') {
            weight +=
                parseFloat(item['weight']) * item['orderItem']['quantity']; // convert to float in case it's a string
        }
    });
    return weight;
}

function lockOrder(order) {
    return db
        .collection('orders')
        .doc(order.order.id.toString())
        .update({ is_locked: true });
}

function unlockOrder(order) {
    return db
        .collection('orders')
        .doc(order.order.id.toString())
        .update({ is_locked: false });
}

export {
    getOrder,
    getAssignedOrders,
    getUnassignedOrders,
    getNextUnassignedOrder,
    getOrderVerification,
    getOrderShippingAddresses,
    getOrdersCount,
    getOrderShipLaterDate,
    getByStatus,
    getCustomerOrders,
    getByNotStatus,
    getVerificationForOrder,
    getOrdersByItem,
    getOrdersByEmail,
    shouldOrderShowCustomerServiceNote,
    markMergedOrderIds,
    mergeOrderItems,
    getOrderItemsWeight,
    lockOrder,
    unlockOrder
};
