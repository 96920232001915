import { db } from '@/firebase';
import store from '@/store/index';

function saveNote(data) {
    return db
        .collection('customer_notes')
        .doc(data.email)
        .set({ notes: data.notes });
}

function getCustomerNotes(email) {
    return db
        .collection('customer_notes')
        .doc(email)
        .get();
}

function shouldShowCustomerNotes(email) {
    let data = store.state.canDisplayCustomerNotes;
    return !(email in data);
}

export { saveNote, getCustomerNotes, shouldShowCustomerNotes };
