import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations, firestoreAction } from 'vuexfire';
import * as fb from '../firebase';
import createPersistedState from 'vuex-persistedstate';
import router from '../router/index';
import { users } from '../data/index';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        currentUser: {},
        unassignedOrders: [],
        assignedOrders: [],
        activeFilter: '',
        currentOrder: {},
        users: [],
        currentOrderQueue: '',
        activeTimeTracker: '',
        activeTimeTrackingOperation: {},
        canDisplayOrderCustomerServiceNote: {},
        currentBatchOrders: []
    },
    plugins: [createPersistedState()],
    mutations: {
        setCurrentUser(state, val) {
            state.currentUser = val;
        },
        ...vuexfireMutations,
        setActiveFilter(state, val) {
            state.activeFilter = val;
        },
        setCurrentOrder(state, val) {
            state.currentOrder = val;
        },
        setCurrentOrderQueue(state, val) {
            state.currentOrderQueue = val;
        },
        setActiveTimeTracker(state, val) {
            state.activeTimeTracker = val;
        },
        setActiveTimeTrackingOperation(state, val) {
            state.activeTimeTrackingOperation = val;
        },
        setUnassignedOrders(state, val) {
            state.unassignedOrders = val;
        },
        setAssignedOrders(state, val) {
            state.assignedOrders = val;
        },
        resetCanDisplayOrderCustomerServiceNoteState(state) {
            state.canDisplayOrderCustomerServiceNote = {};
        },
        setCurrentBatchOrders(state, val) {
            state.currentBatchOrders = val;
        }
    },
    actions: {
        login({ dispatch }, form) {
            return new Promise((resolve, reject) => {
                fb.auth
                    .signInWithEmailAndPassword(form.email, form.password)
                    .then(
                        response => {
                            dispatch('fetchUserProfile', response.user).then(
                                () => {
                                    users.logUserLoginEvent();
                                    resolve(response);
                                }
                            );
                        },
                        error => {
                            reject(error);
                        }
                    );
            });
        },

        // eslint-disable-next-line no-unused-vars
        async signup({ dispatch }, form) {
            const createUserUrl = `${process.env.VUE_APP_FIREBASE_FUNCTION_ROOT}/createNewUser`;
            const authToken = await fb.auth.currentUser.getIdToken(true);
            return fetch(
                createUserUrl +
                    '?email=' +
                    form.email +
                    '&name=' +
                    form.name +
                    '&password=' +
                    form.password +
                    '&token=' +
                    authToken
            );
        },

        // eslint-disable-next-line no-unused-vars
        resetPassword({ dispatch }, email) {
            return new Promise((resolve, reject) => {
                fb.auth.sendPasswordResetEmail(email).then(
                    response => {
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },

        // eslint-disable-next-line no-unused-vars
        updatePassword({ dispatch }, user) {
            const updatePasswordUrl = `${process.env.VUE_APP_FIREBASE_FUNCTION_ROOT}/updateUserPassword`;
            return fetch(
                updatePasswordUrl +
                    '?uid=' +
                    user.uid +
                    '&newPassword=' +
                    user.password
            );
        },

        // eslint-disable-next-line no-unused-vars
        deleteUser({ dispatch }, uid) {
            const deleteUserUrl = `${process.env.VUE_APP_FIREBASE_FUNCTION_ROOT}/deleteUser`;
            return fetch(deleteUserUrl + '?uid=' + uid);
        },

        async fetchUserProfile({ commit }, user) {
            const userProfile = await fb.db
                .collection('users')
                .doc(user.uid)
                .get();
            let userData = userProfile.data();
            userData.uid = user.uid;

            commit('setCurrentUser', userData);
            if (router.currentRoute.path === '/login') {
                router.push('/');
            }
        },

        async logout({ commit }) {
            await fb.auth.signOut();
            users.logUserLogoutEvent();
            commit('setCurrentUser', {});
            commit('setActiveFilter', '');
            commit('setCurrentOrder', {});
            commit('setActiveTimeTracker', '');
            commit('setActiveTimeTrackingOperation', {});
            commit('resetCanDisplayOrderCustomerServiceNoteState');
            commit('setCurrentBatchOrders', []);

            if (router.currentRoute.path !== '/login') {
                router.push('/login');
            }
        },

        applyFilter({ commit, dispatch }, filter) {
            commit('setActiveFilter', filter);
            dispatch('bindUnassignedOrders');
            dispatch('bindAssignedOrders');
        },

        resetFilter({ commit, dispatch }) {
            commit('setActiveFilter', '');
            dispatch('bindUnassignedOrders');
            dispatch('bindAssignedOrders');
        },

        bindUnassignedOrders: firestoreAction(async context => {
            if (!context.state.currentUser.uid) {
                return false;
            }
            let collection = fb.db.collection('orders');
            collection = collection
                .where('internalStatus', '==', 'queued')
                .where('verification', '!=', null)
                .where('assigned', '==', null);

            if (context.state.activeFilter !== '') {
                collection = await context.dispatch(
                    'applyFilterToCollection',
                    collection
                );
            }
            collection = collection
                .orderBy('verification')
                .orderBy('order.date_created', 'asc')
                .orderBy('order.shipping_priority', 'desc')
                .limit(50);

            if (context.state.activeFilter === 'gift') {
                const unassignedOrders = await context.dispatch(
                    'filterOrdersCollectionForGiftItems',
                    collection
                );
                return context.commit('setUnassignedOrders', unassignedOrders);
            } else if (context.state.activeFilter === 'grocery') {
                const unassignedOrders = await context.dispatch(
                    'filterOrdersCollectionForGroceryItems',
                    collection
                );
                return context.commit('setUnassignedOrders', unassignedOrders);
            } else {
                return context.bindFirestoreRef('unassignedOrders', collection);
            }
        }),

        bindAssignedOrders: firestoreAction(async context => {
            if (!context.state.currentUser.uid) {
                return false;
            }
            let collection = fb.db.collection('orders');
            collection = collection
                .where('internalStatus', 'in', ['queued', 'in-progress'])
                .where('verification', '!=', null)
                .where('assigned', '==', context.state.currentUser.uid);

            if (context.state.activeFilter !== '') {
                collection = await context.dispatch(
                    'applyFilterToCollection',
                    collection
                );
            }
            collection = collection
                .orderBy('verification')
                .orderBy('order.date_created', 'asc')
                .orderBy('order.shipping_priority', 'desc')
                .limit(50);

            if (context.state.activeFilter === 'gift') {
                const assignedOrders = await context.dispatch(
                    'filterOrdersCollectionForGiftItems',
                    collection
                );
                return context.commit('setAssignedOrders', assignedOrders);
            } else if (context.state.activeFilter === 'grocery') {
                const assignedOrders = await context.dispatch(
                    'filterOrdersCollectionForGroceryItems',
                    collection
                );
                return context.commit('setAssignedOrders', assignedOrders);
            } else {
                return context.bindFirestoreRef('assignedOrders', collection);
            }
        }),

        applyFilterToCollection({ state }, collection) {
            const filters = {
                gift: () => collection,
                grocery: () => collection,
                perishable: () => collection.where('is_perishable', '==', true),
                'non-perishable': () =>
                    collection.where('is_perishable', '==', false)
            };
            collection = filters[state.activeFilter]?.();
            return collection;
        },

        // eslint-disable-next-line no-unused-vars
        async filterOrdersCollectionForGiftItems({ context }, collection) {
            const orders = await collection.get().then(async querySnapshot => {
                return querySnapshot.docs.map(doc => doc.data());
            });

            const giftItemsSkus = [
                'GIFT BOX',
                'GIFT BOX-POB',
                'gift collection'
            ];

            let ordersWithGiftItems = [];
            orders.every(order => {
                if (giftItemsSkus.some(v => order.items.includes(v)) === true) {
                    ordersWithGiftItems.push(order);
                }
                return true;
            });
            return ordersWithGiftItems;
        },

        // eslint-disable-next-line no-unused-vars
        async filterOrdersCollectionForGroceryItems({ context }, collection) {
            const orders = await collection.get().then(async querySnapshot => {
                return querySnapshot.docs.map(doc => doc.data());
            });

            const giftItemsSkus = [
                'GIFT BOX',
                'GIFT BOX-POB',
                'gift collection'
            ];

            let ordersWithGroceryItems = [];
            orders.every(order => {
                if (
                    giftItemsSkus.some(v => order.items.includes(v)) === false
                ) {
                    ordersWithGroceryItems.push(order);
                }
                return true;
            });
            return ordersWithGroceryItems;
        },

        async showNextUnassignedOrder({ state, dispatch }, offset) {
            return new Promise((resolve, reject) => {
                if (offset in state.unassignedOrders) {
                    dispatch(
                        'assignCurrentOrder',
                        state.unassignedOrders[offset].order.id
                    );
                    let response = {};
                    response.lastOrder =
                        offset === state.unassignedOrders.length - 1;
                    response.firstOrder = offset === 0;
                    resolve(response);
                } else {
                    reject({ message: 'wrong offset' });
                }
            });
        },

        async showNextAssignedOrder({ state, dispatch }, offset) {
            return new Promise((resolve, reject) => {
                if (offset in state.assignedOrders) {
                    dispatch(
                        'assignCurrentOrder',
                        state.assignedOrders[offset].order.id
                    );
                    let response = {};
                    response.lastOrder =
                        offset === state.assignedOrders.length - 1;
                    response.firstOrder = offset === 0;
                    resolve(response);
                } else {
                    reject({ message: 'wrong offset' });
                }
            });
        },

        bindUsers: firestoreAction(context => {
            if (!context.state.currentUser.uid) {
                return false;
            }
            return context.bindFirestoreRef('users', fb.db.collection('users'));
        }),

        updateOrder: firestoreAction((context, payload) => {
            return new Promise((resolve, reject) => {
                fb.db
                    .collection('orders')
                    .doc(payload.orderId.toString())
                    .update(payload.data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }),

        assignCurrentOrder: firestoreAction(
            async ({ bindFirestoreRef }, orderId) => {
                await new Promise((resolve, reject) => {
                    bindFirestoreRef(
                        'currentOrder',
                        fb.db.collection('orders').doc(orderId.toString())
                    )
                        .then(res => {
                            resolve(res);
                        })
                        .catch(err => {
                            reject(err);
                        });
                });
            }
        ),

        assignCurrentBatchOrder({ commit }, batchOrders) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                commit('setCurrentBatchOrders', batchOrders);
                resolve(true);
            });
        },

        // eslint-disable-next-line no-unused-vars
        updateVerification({ commit }, payload) {
            return new Promise((resolve, reject) => {
                fb.db
                    .collection('verification')
                    .doc('order-' + payload.orderId)
                    .update(payload.data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        // eslint-disable-next-line no-unused-vars
        updateVerificationItem({ commit }, payload) {
            return new Promise((resolve, reject) => {
                fb.db
                    .collection('verification_item')
                    .doc('order-' + payload.orderId + '-item-' + payload.itemId)
                    .update(payload.data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        // eslint-disable-next-line no-unused-vars
        createRestockItem({ commit }, payload) {
            fb.db.collection('restock_item').add(payload);
        },

        assignOrderQueue({ commit }, value) {
            commit('setCurrentOrderQueue', value);
        },

        setActiveTimeTracker({ commit }, value) {
            commit('setActiveTimeTracker', value);
        },

        setActiveTimeTrackingOperation({ commit }, value) {
            commit('setActiveTimeTrackingOperation', value);
        },

        setOrderCustomerServiceNoteToNotShowAgain({ state }, orderId) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                state.canDisplayOrderCustomerServiceNote[orderId] = false;
                resolve(true);
            });
        },

        // eslint-disable-next-line no-unused-vars
        updateUserRole({ commit }, payload) {
            return new Promise((resolve, reject) => {
                fb.db
                    .collection('users')
                    .doc(payload.userId)
                    .update({ role: payload.role })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    },
    getters: {
        getUnAssignedOrdersCount(state) {
            return state.unassignedOrders.length;
        },
        getAssignedOrderCount(state) {
            return state.assignedOrders.length;
        },
        getCurrentOrder(state) {
            return state.currentOrder;
        },
        getCurrentUser(state) {
            return state.currentUser;
        }
    },
    modules: {}
});
