import { db } from '@/firebase';
import store from '@/store/index';

function get(orderId) {
    return db
        .collection(`user_activities`)
        .where('order_id', '==', orderId)
        .orderBy('created', 'desc')
        .get();
}

async function getOrdersActivityWithinDateRange(startDate, endDate) {
    const ordersHistory = await db
        .collection('user_activities')
        .where('created', '>=', startDate)
        .where('created', '<=', endDate)
        .orderBy('created', 'asc')
        .get();

    return ordersHistory.docs;
}

function save(payload) {
    return db.collection(`user_activities`).add({
        order_id: Number(payload.orderId),
        created: Date.parse(new Date()),
        user: store.state.currentUser.displayName,
        action: payload.action,
        description: payload.description
    });
}

export { get, getOrdersActivityWithinDateRange, save };
