import { db } from '@/firebase';

function saveReport(reportOption, report) {
    return db.collection('inventory_reports').add({
        option: reportOption,
        data: report,
        created_at: Date.parse(new Date())
    });
}

function getReports() {
    return db
        .collection('inventory_reports')
        .orderBy('created_at', 'desc')
        .get();
}

function getReport(id) {
    return db
        .collection('inventory_reports')
        .doc(id.toString())
        .get();
}

function deleteReport(id) {
    return db
        .collection('inventory_reports')
        .doc(id.toString())
        .delete();
}

export { saveReport, getReports, getReport, deleteReport };
