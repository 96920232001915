import { db } from '@/firebase';

/**
 * return verification document by ID from DB (Firebase)
 * @returns {*}
 */
function getVerification(verificationId) {
    return db
        .collection('verification')
        .where('id', '==', verificationId)
        .get();
}

/**
 * return verification document by order_id from DB (Firebase)
 * @returns {*}
 */
function getByOrder(orderId) {
    return db
        .collection('verification')
        .where('order_id', '==', orderId)
        .get();
}

/**
 * return verification document by cart_id from DB (Firebase)
 * @returns {*}
 */
function getByCart(cartId) {
    return db
        .collection('verification')
        .where('cartId', '==', cartId)
        .get();
}

/**
 * return verification documents collection matching given status from DB (Firebase)
 * @returns {*}
 */
function getByStatus(status) {
    return db
        .collection('verification')
        .where('status', '==', status)
        .get();
}

export { getVerification, getByOrder, getByCart, getByStatus };
