import { db } from '@/firebase';
import store from '@/store/index';

function startTimer(operation) {
    return db.collection('time_tracker').add({
        operation: operation,
        start: Date.parse(new Date()),
        user: store.state.currentUser.uid,
        isPausedByLogout: false
    });
}

function stopTimer() {
    return db
        .collection('time_tracker')
        .doc(store.state.activeTimeTracker)
        .update({ stop: Date.parse(new Date()) });
}

function stopTimerWithPause() {
    return db
        .collection('time_tracker')
        .doc(store.state.activeTimeTracker)
        .update({ stop: Date.parse(new Date()), isPausedByLogout: true });
}

function getReport(startDate = '', endDate = '', user = '') {
    let collection = db.collection('time_tracker');
    if (user != '') {
        collection = collection.where('user', '==', user);
    }
    return collection
        .where('start', '>=', startDate)
        .where('start', '<=', endDate)
        .orderBy('start', 'asc')
        .get();
}

function updateOperation(operation) {
    return db
        .collection('time_tracker')
        .doc(store.state.activeTimeTracker)
        .update({ operation: operation });
}

function getLastTrackedActivity() {
    return db
        .collection('time_tracker')
        .where('user', '==', store.state.currentUser.uid)
        .orderBy('stop', 'desc')
        .limit(1)
        .get();
}

export {
    startTimer,
    stopTimer,
    stopTimerWithPause,
    getReport,
    updateOperation,
    getLastTrackedActivity
};
