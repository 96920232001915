<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            v-bind:style="{ zIndex: 200 }"
        >
            <v-card>
                <v-card v-show="note !== ''">
                    <v-card-title>
                        <span class="text-h5">Customer Service Note </span>
                    </v-card-title>
                    <v-card-text class="pt-5">
                        <v-row
                            ><v-col
                                cols="12"
                                style="text-align: left; border: 1px solid #000"
                            >
                                <span>{{ note }}</span>
                            </v-col></v-row
                        >
                    </v-card-text>
                    <v-spacer></v-spacer>
                </v-card>
                <v-card v-show="customerNotes !== ''">
                    <v-card-title>
                        <span class="text-h5">Customer Notes</span>
                    </v-card-title>
                    <v-card-text class="pt-5">
                        <v-row
                            ><v-col
                                cols="12"
                                style="text-align: left; border: 1px solid #000"
                            >
                                <span>{{ customerNotes }}</span>
                            </v-col></v-row
                        >
                    </v-card-text>
                </v-card>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        type="button"
                        @click.native="hideCustomerServiceNote"
                    >
                        Do not show this again
                    </v-btn>
                    <v-btn color="primary" type="button" @click.native="hide">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        orderId: '',
        note: '',
        customerNotes: '',
        resolve: null,
        reject: null
    }),
    methods: {
        show(order_id, note, customer_notes = '') {
            this.dialog = true;
            this.orderId = order_id;
            this.note = note;
            this.customerNotes = customer_notes;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        hide() {
            this.resolve(true);
            this.dialog = false;
            this.note = '';
        },

        hideCustomerServiceNote() {
            const self = this;
            this.$store
                .dispatch(
                    'setOrderCustomerServiceNoteToNotShowAgain',
                    self.orderId
                )
                .then(() => {
                    self.hide();
                });
        }
    }
};
</script>
